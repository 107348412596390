const articles = [
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/158/how-to-set-up-your-wpx-hosting-account/",
    text: "How To Set Up Your WPX Hosting Account"
  },
  {
    link: "https://wpxhosting.com/knowledgebase/article/132/glossary-of-terms/",
    text: "Glossary of Terms"
  },
  {
    link: "https://wpxhosting.com/knowledgebase/article/117/part-value/",
    text: "Part 4 - Value"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/116/part-3---free-plugins-and-tools/",
    text: "Part 3 - Free Plugins and Tools"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/115/part-2---saving-time-and-money/",
    text: "Part 2 - Saving Time and Money"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/114/part-1---a-big--warm-welcome/",
    text: "Part 1 - A Big, Warm Welcome"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/113/how-wpx-hosting-helps-350--homeless-dogs-every-day/",
    text: "How WPX Hosting Helps 350+ Homeless Dogs Every Day"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/138/how-to-use-the-staging-area/",
    text: "How to Use the Staging Area"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/141/what-is-the-wpx-hosting-fixed-for-you-guarantee-/",
    text: "What is the WPX Hosting 'Fixed For You' Guarantee?"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/163/does-wpx-hosting-offer-managed-wordpress-hosting-what-exactly-does-that-cover-/",
    text:
      "Does WPX Hosting Offer Managed WordPress Hosting & What Exactly Does That Cover?"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/51/is-site-migration-to-wpx-hosting-free-is-email-included-/",
    text: "Is Site Migration to WPX Hosting Free & is Email Included?"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/31/can-i-host-my-non-usa-e-g-uk-website-in-the-usa-rank-in-google-in-my-country-/",
    text:
      "Can I host my Non-USA (e.g. UK) Website in the USA & Rank in Google in my Country?"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/25/does-wpx-hosting-keep-backups-of-hosted-sites-/",
    text: "Does WPX Hosting Keep Backups of Hosted Sites?"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/26/does-wpx-hosting-have-an-affiliate-program-/",
    text: "Does WPX Hosting Have An Affiliate Program?"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/15/do-subdomains---subfolders-consume--hosting-slots--in-my-wpx-account-/",
    text:
      "Do Subdomains & Subfolders Consume 'Hosting Slots' in my WPX Account?"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/12/can-i-purchase-extra-ips-from-wpx-hosting-/",
    text: "Can I Purchase Extra IPs from WPX Hosting?"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/17/can-i-use-soap-simple-object-access-protocol-with-wpx-hosting-/",
    text: "Can I use SOAP (Simple Object Access Protocol) with WPX Hosting?"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/32/where-is-wpx-hosting-s-datacenter-located-/",
    text: "Where is WPX Hosting's Datacenter Located?"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/34/is-wpx-hosting-set-up-for-seo-hosting-/",
    text: "Is WPX Hosting Set Up For SEO Hosting?"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/162/what-are-the-limits-to-site-optimization-at-wpx-hosting-/",
    text: "What Are The Limits To Site Optimization At WPX Hosting?"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/35/does-wpx-hosting-sell-ssl-certificates-or-help-with-ssl-integration-/",
    text: "Does WPX Hosting sell SSL Certificates or help with SSL Integration?"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/36/does-wpx-hosting-support-ioncube-/",
    text: "Does WPX Hosting Support Ioncube?"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/39/what-is-the-wpx-hosting-refund-policy-/",
    text: "What Is The WPX Hosting Refund Policy?"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/52/how-can-i-transfer-my-domain-to-wpx-hosting-/",
    text: "How Can I Transfer my Domain to WPX Hosting?"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/59/does-wpx-hosting-support-php-x-/",
    text: "Does WPX Hosting Support PHP 7.x?"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/61/what-version-of-php---mysql-are-currently-running-on-wpx-hosting-/",
    text: "What Version Of PHP & MySQL Are Currently Running On WPX Hosting?"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/62/can-i-use-phpmyadmin-with-a-wpx-hosting-account-/",
    text: "Can I Use phpMyAdmin With A WPX Hosting Account?"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/68/do-i-need-a-content-delivery-network-to-speed-up-my-website-/",
    text: "Do I need a Content Delivery Network to speed up my Website?"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/70/does-wpx-hosting-offer-free-ssl-certificates-/",
    text: "Does WPX Hosting offer Free SSL Certificates?"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/82/did-traffic-planet-hosting-change-its-name-to-wpx-hosting-why-/",
    text: "Did Traffic Planet Hosting change its name to WPX Hosting & Why?"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/99/what-is-the-difference-between-php-versions-/",
    text: "What is the difference between PHP versions?"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/94/how-to-cancel-your-wpx-hosting-account/",
    text: "How to Cancel your WPX Hosting Account"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/104/billing-with-wpx-hosting/",
    text: "Billing with WPX Hosting"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/123/3-reasons-why-wpx-hosting-helps-digital-marketers/",
    text: "3 Reasons Why WPX Hosting helps Digital Marketers"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/128/what-happens-if-i-exceed-my-bandwidth-limit-/",
    text: "What Happens if I Exceed My Bandwidth Limit?"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/153/a-non-technical-guide-to-speeding-up-your-websites/",
    text: "A Non-Technical Guide To Speeding Up Your Websites"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/155/introducing-the-wpx-cloud-why-your-website-needs-it/",
    text: "INTRODUCING THE WPX CLOUD & WHY YOUR WEBSITE NEEDS IT"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/160/photography-website-hosting-/",
    text: "Photography Website Hosting?"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/161/divi-theme-hosting-who-is-the-fastest-elegant-themes-divi-host-/",
    text: "Divi Theme Hosting? Who Is The Fastest Elegant Themes Divi Host?"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/156/introducing-the-wpx-cloud-why-your-website-needs-it/",
    text: "Introducing The WPX Cloud & Why Your Website Needs It"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/154/do-performance-grades-on-pingdom-tools-gtmetrix-matter-/",
    text: "Do 'Performance Grades' On Pingdom Tools & GTMetrix Matter?"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/166/my-site-still-seems-slow-after-moving-to-wpx-why-/",
    text: "My Site Still Seems Slow After Moving To WPX – Why?"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/56/our-website-migration-policy/",
    text: "Our Website Migration Policy"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/11/is-site-migration-to-wpx-hosting-free-is-email-included-/",
    text: "Is Site Migration to WPX Hosting Free & is Email included?"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/16/is-site-migration-from-wp-engine-possible-free-/",
    text: "Is Site Migration from WP Engine Possible & Free?"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/41/how-to-transfer-a-domain-to-wpx-hosting/",
    text: "How To Transfer a Domain To WPX Hosting"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/112/how-to-complete-the-new-migration-form-guide-video-/",
    text: "How to Complete the New Migration Form (Guide & Video)"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/10/how-to-set-up-cron-jobs/",
    text: "How To Set Up Cron Jobs"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/57/how-to-re-subscribe-with-paypal-what-you-should-do-after-up-downgrading/",
    text:
      "How to (re)subscribe with PayPal & what you should do after up/downgrading"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/63/how-to-use-filezilla-for-ftp-sftp-with-your-wpx-hosting-account-/",
    text: "How To Use Filezilla For FTP/SFTP With Your WPX Hosting Account?"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/76/how-to-change-the-php-version-of-your-website/",
    text: "How To Change The PHP Version of Your Website"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/79/how-to-create-ftp-users/",
    text: "How To Create FTP Users"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/85/how-to-add-a-new-website-to-your-hosting-account/",
    text: "How To Add a New Website To Your Hosting Account"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/86/how-to-install-wordpress-with-one-click/",
    text: "How To Install WordPress with One Click"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/88/i-want-my-website-ssl-enabled-with-let-s-encrypt---how-do-i-do-that-/",
    text: "I want my website SSL enabled with Let's Encrypt – how do I do that?"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/126/how-do-i-preview-a-website-hosted-on-wpx-hosting-without-pointing-to-the-domain-/",
    text:
      "How Do I Preview a Website Hosted on WPX Hosting without Pointing to the Domain?"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/90/how-do-i-configure-my-existing-website-with-cloudflare-/",
    text: "How do I Configure my Existing Website with CloudFlare?"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/101/how-to-change-your-main-domain/",
    text: "How To Change Your Main Domain"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/110/how-to-access-your-error-logs/",
    text: "How to access your Error Logs"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/111/how-to-view-bandwidth--traffic--and-mail-usage-for-each-website/",
    text: "How to View Bandwidth, Traffic, and Mail usage for each Website"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/89/how-can-i-develop-a-website-without-pointing-it-to-wpx-hosting-/",
    text: "How can I develop a website without pointing it to WPX Hosting?"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/127/how-to-upgrade-downgrade-your-hosting-service-the-easy-way-/",
    text: "How to Upgrade / Downgrade your Hosting Service (the easy way)"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/130/verify-your-website-in-google-search-console/",
    text: "Verify your Website in Google Search Console"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/131/changing-your-google-console-website-address/",
    text: "Changing your Google Console Website Address"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/151/how-to-clear-your-browser-cache-chrome-firefox-edge-/",
    text: "How To Clear your Browser Cache (Chrome, Firefox, & Edge)"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/157/how-to-add-database-users-easily/",
    text: "How to add database users easily"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/167/how-to-setup-dns-records-for-the-most-common-rd-party-services-google-apps-zoho-office-/",
    text:
      "How to setup DNS records for the most common 3rd party services (Google Apps, Zoho, Office 365)?"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/168/how-to-change-your-billing-cycle-the-easy-way-/",
    text: "How to change your billing cycle (the easy way)"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/150/the-different-types-of-domain-redirects/",
    text: "The Different Types of Domain Redirects"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/87/how-to-point-your-domain-s-nameservers-to-wpx/",
    text: "How to Point your Domain's Nameservers to WPX in 3 Steps"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/73/how-to-change-cloudflare-s-dns-a-records-in-steps/",
    text: "How To Change Cloudflare''s DNS (A)Records in 3 Steps"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/60/how-to-change-your-site-s-godaddy-nameservers-to-wpx-hosting/",
    text: "How To Change your Site's GoDaddy Nameservers to WPX Hosting"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/50/what-is-dns-propagation-and-why-can-it-take-up-to-48-hours-/",
    text: "What is DNS Propagation and why can it take up to 48 hours?"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/159/what-is-the-wordpress-gutenberg-update-do-i-need-it-/",
    text: "What is the WordPress 'Gutenberg' Update & Do I Need It?"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/6/setting-up-amazon-cloudfront-cdn-with-your-wordpress-site/",
    text: "Setting Up Amazon Cloudfront CDN With Your WordPress Site"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/14/can-i-install-wordpress-multisite-/",
    text: "Can I Install WordPress Multisite?"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/23/can-gravatars-slow-down-a-wordpress-blog-/",
    text: "Can Gravatars Slow Down A WordPress Blog?"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/43/w3-total-cache-guide/",
    text: "W3 Total Cache Guide"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/83/why-wpx-hosting-recommends-w3-total-cache/",
    text: "Why WPX Hosting recommends W3 Total Cache"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/149/how-to-install-recaptcha-on-your-website-/",
    text: "How to Install reCAPTCHA on your Website?"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/100/brute-force-protection-security-measures/",
    text: "Brute Force Protection Security Measures"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/45/why--nulled---cracked-pirate--plugins---themes-are-forbidden-on-wpx-hosting/",
    text:
      "Why 'Nulled' (Cracked/Pirate) Plugins & Themes are Forbidden on WPX Hosting"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/74/does-wpx-hosting-offer-ddos-protection-/",
    text: "Does WPX Hosting offer DDoS Protection?"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/80/how-to-disable-theme-and-plugin-file-editing-in-your-dashboard/",
    text: "How to Disable Theme and Plugin File Editing in your Dashboard"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/102/protect-your-wp-admin-area/",
    text: "Protect Your WP Admin Area"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/103/wordpress-security-tips/",
    text: "WordPress Security Tips"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/107/my-site-has-been-hacked-has-malware-what-now-/",
    text: "My Site has been Hacked & has Malware – What Now?"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/124/avada-wordpress-vulnerability/",
    text: "Avada WordPress Vulnerability"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/142/ip-security-options/",
    text: "IP Security Options"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/145/automatic-wordpress-updates/",
    text: "Automatic WordPress Updates"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/147/what-is-the-eu-s-general-data-protection-regulation-gdpr-/",
    text: "What is the EU's General Data Protection Regulation (GDPR)?"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/165/does-wpx-hosting-remove-malware-for-customers-is-it-free-do-i-also-need-sucuri-or-wordfence-protection-/",
    text:
      "Does WPX Hosting Remove Malware For Customers & Is It Free? Do I Also Need Sucuri Or Wordfence Protection?"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/8/setting-up-a-new-wpx-email-account/",
    text: "Setting Up a New WPX Email Account"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/13/how-to-access-your-free-wpx-webmail-account/",
    text: "How to Access your Free WPX Webmail Account"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/28/can-i-send-bulk-emails-from-my-wpx-hosting-account-/",
    text: "Can I Send Bulk Emails From My WPX Hosting Account?"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/48/what-is-the-difference-between-pop-and-imap-mail-servers-/",
    text: "What is the Difference between POP and IMAP Mail Servers?"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/54/how-to-set-up-an-email-filter/",
    text: "How to Set Up an Email Filter"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/58/how-to-set-up-email-forwarding/",
    text: "How to Set Up Email Forwarding"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/69/how-to-connect-your-wpx-email-account-with-mac-mail/",
    text: "How to Connect your WPX Email Account with Mac Mail"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/93/how-to-connect-your-wpx-email-account-with-outlook/",
    text: "How to Connect your WPX Email Account with Outlook"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/98/how-to-connect-your-wpx-email-account-to-your-android-smartphone/",
    text: "How to Connect your WPX Email Account to your Android Smartphone"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/105/how-to-connect-your-wpx-email-account-with-gmail/",
    text: "How to Connect your WPX Email Account with Gmail"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/108/how-to-connect-your-wpx-email-account-with-ios-mail/",
    text: "How to Connect your WPX Email Account with iOS Mail"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/144/how-to-connect-your-wpx-email-account-with-thunderbird/",
    text: "How to Connect your WPX Email Account with Thunderbird"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/164/how-to-connect-your-email-account-with-windows-mail/",
    text: "How to Connect Your Email Account with Windows 10 Mail"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/139/pingdom-false-positives/",
    text: "Pingdom False Positives"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/38/what-are-the-limits-of-wpx-s-technical-support-/",
    text: "What are the Limits of WPX's Technical Support?"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/37/my-site-is-down-or-running-slowly--what-can-i-do-/",
    text: "My Site is Down or Running Slowly, What Can I Do?"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/30/wp-plugins-themes-can-be-great-terrible-for-your-site/",
    text: "WP Plugins & Themes: Can Be Great & Terrible For Your Site"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/29/is-my-website-displaying-an-error-to-me-or-my-visitors-too-/",
    text: "Is my Website Displaying an Error to Me or my Visitors too?"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/24/what-if-i-see-errors-on-my-site-/",
    text: "What if I See 403 Errors On My Site?"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/22/what-if-i-mistype-or-misspell-a-domain-when-buying-/",
    text: "What if I Mistype or Misspell a Domain when Buying?"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/134/adding-a-developer-as-a-contact/",
    text: "Adding a Developer as a Contact"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/133/backup-manager-guide/",
    text: "Backup Manager Guide"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/65/understanding-the-dashboard-menu/",
    text: "Understanding the Dashboard Menu"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/64/does-wpx-hosting-use-cpanel-or-another-admin-platform/",
    text: "Does WPX Hosting use cPanel or another Admin Platform"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/78/how-to-access-wpx-hosting-s-file-manager-and-what-can-you-do-with-it/",
    text: "How to access WPX Hosting's File Manager and what can you do with it"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/146/automatic-domain-renewal/",
    text: "Automatic Domain Renewal"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/137/which-tlds-do-not-allow-whois-privacy-/",
    text: "Which TLDs do not allow WHOIS Privacy?"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/135/the-benefits-of-whois-privacy/",
    text: "The Benefits of WHOIS Privacy"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/129/domain-expiration-and-renewal/",
    text: "Domain Expiration and Renewal"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/97/new-icann-inter-registrar-transfer-policy/",
    text: "New ICANN Inter-Registrar Transfer Policy"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/140/refund-policy-update--november-2017-/",
    text: "Refund Policy Update (November 2017)"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/122/ransomware-cyber-attack-may-2017/",
    text: "Ransomware Cyber-Attack May 2017"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/121/introducing-http-2-and-what-it-means-for-you-/",
    text: "IntroducingHTTP/2 and what it means for you!"
  },
  {
    link:
      "https://wpxhosting.com/knowledgebase/article/120/does-wpx-hosting-offer-servers-located-in-the-uk-/",
    text: "Does WPX Hosting Offer Servers Located In The UK?"
  }
];

export default articles;
