<template>
  <div id="app">
    <HomeComponent :articles="articles" />
  </div>
</template>

<script>
import HomeComponent from "./components/HomeComponent.vue";
import articles from "./data/articles";

export default {
  name: "app",
  data() {
    return {
      articles,
      test: "test123"
    };
  },
  components: {
    HomeComponent
  },
  methods: {
    updateArticles(searchedText) {
      let currentArticles = articles.filter(function(article) {
        return article.text.toLowerCase().includes(searchedText);
      });
      this.articles = currentArticles;

      // console.log(this.articles);
    },
    addEventListeners() {
      this.$root.$on("updatedSearch", this.updateArticles);
    }
  },
  mounted() {
    this.addEventListeners();
  }
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
