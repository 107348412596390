<template>
  <div class="container">
    <h1 class="display-3">Quick Search</h1>
    <h5 class=".subheading">Scraped at 7:50PM GMT +2 on 23/7/2019</h5>
    <br />
    <v-text-field label="Solo" :placeholder="thePlaceholder" v-model="searchedArticle" solo></v-text-field>
    <ul>
      <li v-for="(article, index) in articles" :key="index">
        <a target="_blank" :href="article.link">{{article.text}}</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "HomeComponent",
  data() {
    return {
      searchedArticle: "",

      // This archive must be stored in the database as the Articles Array after they are cralwed by the crawler
      searchPlaceholderArray: [
        "How to make money?",
        "Should I click or should I press?",
        "I am the dev and you are the suport peeps!",
        "My site is down, please help(not our hosting)",
        "Do you kno di, wae my bruddah?",
        "Thank you for leaving us",
        "Empty error message in dashboard",
        "I was FORCED to signup with a domain I never was planning on using. I'm not happy about this!",
        "I'm not sure, the lady on your online chat suggested I did the migration before contacting them?",
        "But does migration process halt or destroy my current site?",
        "This is NO JOKE!",
        "Hostgator sucks !!!!!!",
        "How you doing?",
        "Try now, I did something!",
        "One more question",
        "Hi, can I help you here? -yesyes",
        "Is it ok to cut and paste the password instead of typing it in?",
        "God bless you.. your plugins are best.",
        "Ultra_Human_backup.tar.gz",
        "NE_PIPAI.tar.gz",
        "Do I even have an account?",
        "So if I leave Wordpress can I use your cloudflare?",
        "Dear Customer, We are sorry, but no.",
        "Okay are you on WhatsApp you we can chat much better there pls",
        "I am extremely dissatisfied WP ENgine customer.",
        "Mayoneza",
        "Brain pill?",
        "Am I a joke to you?",
        "I will have to hire cloudflare.",
        "Proverqvaite za cache predi da eskalirate kum men :(",
        "Not great, not terrible",
        "Nobody: Client: My site is slow",
        "sudo rm -rf /",
        "Make backups before testing on live"
      ]
    };
  },
  computed: {
    thePlaceholder: function() {
      //   return "wtf";
      return this.searchPlaceholderArray[
        Math.floor(this.searchPlaceholderArray.length * Math.random())
      ];
    }
  },
  props: {
    articles: {
      type: Array,
      required: true
    }
  },
  watch: {
    //This must be in the partent controller
    searchedArticle: function(newValue) {
      this.$root.$emit("updatedSearch", newValue);
    }
  }
};
</script>

<style scoped>
li {
  list-style: none;
  text-align: left;
}
li a {
  font-size: 1.3em;
  text-decoration: none;
}
.container {
  width: 40%;
  margin: auto;
  /* background-color: azure; */
}

@media only screen and (max-width: 1400px) {
  .container {
    width: 70%;
    margin: auto;
    /* background-color: azure; */
  }
}
</style>
